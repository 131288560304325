<template>
<div class="gbook-add-entry">
      <div class="text-center font-italic">
           Skriv ditt nya gästboksinlägg och klicka på knappen "Spara".<br>
           Tänk på att hålla en god ton och ett vårdat språk!
      </div>
      <div v-if="!loading">
            <div>
                  <div class="mt-2 mb-2">
                        <input v-model="entry.title" type="text" placeholder="Skriv rubrik här..." class="form-control" />
                  </div>
                  <div class="mt-2 mb-2">
                        <b-form-textarea placeholder="Skriv ditt meddelande här..." rows="3" max-rows="8" v-model="entry.message"></b-form-textarea>
                  </div>
            </div>
            <div v-if="saveerrors.length > 0" class="mt-2 mb-t ">
                  <b-alert variant="danger" show>
                        <ul>
                              <li v-for="(error, index) in saveerrors" :key="index">{{error}}</li>
                        </ul>
                  </b-alert>
            </div>
            <div class="text-center mt-4">
                  <b-button @click="Close()" variant="secondary" class="m-1">Avbryt</b-button>
                  <b-button @click="SaveEntry()" variant="primary" class="m-1">Spara</b-button>
            </div>
      </div>
      <div v-else>
            <Loader loadingtext="Sparar..."></Loader>
      </div>
</div>
</template>

<script>
const Loader = () => import("@/components/miscellaneous/Loader");

export default {
      name: 'GbookAddEntry',
      components: {
            Loader
      },
      data() {
            return {
                  ready: false,
                  saveerrors: [],
                  loading: false,
                  entry: {
                        title: "",
                        message: ""
                  }
            };
      },
      computed: {

      },
      methods: {
            Close() {
                  this.$emit("close");
            },
            SaveEntry() {
                  this.loading = true;
                  var postdata = this.entry;
                  var self = this;
                  this.$store.dispatch("gbookp/addEntry", postdata).then((response) => {                        
                        this.loading = false;
                        this.saveerrors = [];
                        if (response.data.status == "ok")
                              this.$emit("saved");
                        else {
                              this.saveerrors = response.data.errors;
                        }
                  }).catch(error => {
                        self.loading = false;
                        self.saveerrors = error.response.data.errors;
                  });
            }
      }
}
</script>
